*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  @media print {
    -webkit-print-color-adjust: exact;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font-type-main);

  &::-moz-focus-inner {
    border: 0;
  }
}

mark {
  color: inherit;
}

html,
body {
  font-family: var(--font-type-main);
  color: var(--basic-900);
  height: 100%;
}

html {
  font-size: var(--font-size-main);
}

button {
  &:focus {
    outline-style: solid;
    outline-color: transparent;
  }
}

#root {
  height: 100%;
}
