:root {
  --font-type-main: 'Verdana', 'Lato', 'Helvetica Neue', Arial, Helvetica,
    sans-serif;
  --font-type-review-site: 'Open Sans', 'Lato', 'Helvetica Neue', Arial,
    Helvetica, sans-serif;
  --font-type-additional: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica,
    sans-serif;
  --font-type-decorative: 'Roboto', 'Lato', 'Helvetica Neue', Arial, Helvetica,
    sans-serif;
  --font-scale: 1;
  --font-size-main: calc(var(--font-scale) * 1em); /* 16px */
  --page-width-max: 1366px;
  --page-width-min: 320px;
  --content-width-max: 100%;
  --basic-100: #fff;
  --basic-500: #d3d3d3;
  --basic-700: #606060;
  --basic-900: #000;
  --warning-500: #e77800;
  --info-700: #254f8e;
  --info-800: #1e4176;
  --info-900: #152d51;
  --danger-100: #f0dcdf;
  --danger-600: #e21f26;
  --global-grid-size: 8px;
  /* Icon */

  --global-icon-size-xs: 0.5625rem;
  --global-icon-size-sm: 0.85rem;
  --global-icon-size-md: 0.94rem;
  --global-icon-size-lg: 1.03rem;
  --global-icon-size-xl: 1.125rem;
  --global-icon-size-extra-small: 0.875rem; /* 14px */
  --global-icon-size-small: 1rem; /* 16px */
  --global-icon-size: 1.5rem; /* 24px */
  --global-icon-size-large: 1.75rem; /* 28px */

  --button-primary: #293b65;
  --navy-800: #1a2641;
  --navy-600: #767e8d;
  --navy-300: #bfc4d1;
  --purple-800: #5e2a5f;
  --purple-600: #8a6a8a;
  --purple-300: #d6c2d6;
  --red-800: #bf3735;
  --red-600: #d98786;
  --red-300: #ecc3c2;
  --orange-800: #dd8a44;
  --orange-600: #ebb98f;
  --orange-300: #f5dcc7;
  --green-800: #91bc56;
  --green-600: #bed699;
  --green-300: #ddeacd;

  --grey-1: #f5f5f5;
  --grey-2: #c4c4c4;
  --grey-3: #808081;
  --grey-4: #4d4d4d;
  --french-grey-1: #f7fafc;
  --french-grey-2: #cbd5e0;
  --french-grey-3: #a0aec0;
  --french-grey-4: #4a5568;
  --french-grey-5: #45546a;
  --green-1: #f0fff4;
  --green-2: #9ae6b4;
  --green-3: #48bb78;
  --green-4: #80c63f;
  --green-5: #38a169;
  --green-6: #25855a;
  --orange-1: #fbd38d;
  --orange-2: #f78e1e;
  --orange-3: #dd8b44;
  --orange-4: #c05621;
  --orange-5: #f78e20;
  --red-1: #e9d7d7;
  --red-2: #e59999;
  --red-3: #b20401;
  --red-4: #e31b23;
  --red-5: #63171b;
  --blue-1: #ccebff;
  --blue-2: #61baf4;
  --blue-3: #2b6cb0;
  --blue-4: #718096;
  --blue-5: #1e4176;
  --yellow-1: #fffff0;
  --yellow-2: #faf089;
  --yellow-3: #ffda00;
  --yellow-4: #ecc94b;
  --teal-1: #e6fffa;
  --teal-2: #4fd1c5;
  --teal-3: #2c7a7b;
  --teal-4: #1d4044;
  --cyan-1: #edfdfd;
  --cyan-2: #76e4f7;
  --cyan-3: #13b5ea;
  --cyan-4: #0987a0;
  --purple-1: #faf5ff;
  --purple-2: #b794f4;
  --purple-3: #763477;
  --purple-4: #553c9a;
  --white: #ffffff;
  --black: #000000;

  /* General variables */
  --global-border-radius: 4px;
  --general-border-radius: var(--global-border-radius);
  --general-line-height: 1.75rem; /* 28px */
  --general-sm-padding-tb: 3px;
  --general-md-padding-tb: 7px;
  --general-lg-padding-tb: 11px;
  --general-sm-font-size: 0.875rem; /* 14px */
  --general-md-font-size: 1rem; /* 16px */
  --general-lg-font-size: 1.25rem; /* 20px */
  --general-font-family: var(--font-type-main);

  /* Button config */
  --button-sm-padding-lr: 19px;
  --button-md-padding-lr: 23px;
  --button-border-width: 1px;
  --button-sm-min-height: calc(
    var(--button-line-height) +
      (var(--button-sm-padding-tb) + var(--button-border-width)) * 2
  );
  --button-md-min-height: calc(
    var(--button-line-height) +
      (var(--button-md-padding-tb) + var(--button-border-width)) * 2
  );
  --button-lg-min-height: calc(
    var(--button-line-height) +
      (var(--button-lg-padding-tb) + var(--button-border-width)) * 2
  );
  --button-sm-strict-indent: calc(
    -1 * (var(--button-line-height) - var(--global-icon-size-small)) / 2 + var(--global-grid-size)
  );
  --button-sm-strict-offset: calc(
    -1 * (var(--button-line-height) - var(--global-icon-size-small)) / 2
  );
}
